import React, { Fragment } from 'react';
import {Seo, ScrollTopBtn} from '../components';
import {
  HeaderFour,
  HeroThree,
  AboutFive,
  CtaOne,
  ProcessOne,
  FooterOne
} from '../containers';
import { findSeoByPath } from '../utils/utils';

const HomeThree = ()=> {
  const { url , keywords , description }  = findSeoByPath('')

  return (
  <Fragment>
    <Seo title="Home" description={description} keywords={keywords} url={url} />
    <HeaderFour/>
    <HeroThree/>
    <AboutFive className="space-top space-extra-bottom"/>
    <CtaOne className="space"/>
    <ProcessOne className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

  }

export default HomeThree;