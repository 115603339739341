import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, Cloud, FooterOne } from "../containers";
import { findSeoByPath } from '../utils/utils';

const ITStrategy = () => {
  
  const { url , keywords , description }  = findSeoByPath('service/cloud')

  return (
    <Fragment>
      <Seo title="Cloud Solutions" keywords={keywords} description={description} url={url} />
      <HeaderFour />
      <BreadcrumbService
        pageName="Cloud Solutions"
        bgImage="/images/breadcumb/breadcumb1.jpg" 
      />
      <Cloud className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITStrategy;
