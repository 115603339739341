// import React from 'react';
// import Tab from 'react-bootstrap/Tab';
// import Nav from 'react-bootstrap/Nav';
// import {
//   TitleWrap,
//   SecSubTitle,
//   SecTitle,
//   List,
//   Button
// } from '../../components';

// const AboutSix = ({className})=> {

//   const TABDATA = [
//     {
//       "id": "tab1",
//       "title": "Web Development",
//       "subtitle": "Frontend and Backend",
//       "icon": "/images/icon/sr-icon-3-1.png",
//       "image": "/images/about/software-web.jpg",
//       "text": "Our web development service encompasses both frontend and backend solutions to create dynamic, responsive, and user-friendly websites. From designing intuitive user interfaces to building robust server-side architectures, we deliver customized web solutions tailored to your business needs. With expertise in a variety of technologies and frameworks, we ensure high-performance, scalable, and secure web applications that drive user engagement and business growth.",
//       "feature": [
//         "Frontend Development (HTML, CSS, JavaScript)",
//         "Backend Development (Node.js, Python, PHP)",
//         "Database Design and Integration",
//         "Responsive Design and Cross-Browser Compatibility"
//       ],
//       "btntext": "Get Started",
//       "path": "/contact"
//     },
//     {
//       "id": "tab2",
//       "title": "Mobile Development",
//       "subtitle": "iOS and Android",
//       "icon": "/images/icon/sr-icon-3-2.png",
//       "image": "/images/about/software-mobile.jpg",
//       "text": "Our mobile development service offers cutting-edge solutions to bring your app ideas to life. Whether you need iOS, Android, or cross-platform development, our team of experienced developers will create user-friendly, high-performance mobile applications tailored to your specific requirements. From concept to launch and beyond, we provide comprehensive mobile development services to help your business succeed in the rapidly evolving mobile landscape.",
//       "feature": [
//         "iOS and Android Development",
//         "UI/UX Design and Prototyping",
//         "Backend Development and Integration",
//         "Quality Assurance and Testing"
//       ],
//       "btntext": "Get Started",
//       "path": "/contact"
//     },
//     {
//       "id": "tab3",
//       "title": "Ai Applications",
//       "subtitle": "Machine Learning and Ai",
//       "icon": "/images/icon/sr-icon-3-3.png",
//       "image": "/images/about/software-ai.jpg",
//       "text": "Our AI application development service leverages machine learning and artificial intelligence technologies to create intelligent software solutions that automate processes, analyze data, and enhance decision-making. From natural language processing to computer vision, we harness the power of AI to develop innovative applications that optimize operations, improve efficiency, and drive business growth.",
//       "feature": [
//         "Machine Learning Algorithms",
//         "Natural Language Processing",
//         "Computer Vision and Image Recognition",
//         "Predictive Analytics and Data Mining"
//       ],
//       "btntext": "Get Started",
//       "path": "/contact"
//     },
//     {
//       "id": "tab4",
//       "title": "Desktop Application",
//       "subtitle": "Windows and macOS",
//       "icon": "/images/icon/sr-icon-3-4.png",
//       "image": "/images/about/mobile-service.jpg",
//       "text": "Our desktop application development service delivers robust and scalable software solutions for Windows and macOS platforms. Whether you need standalone applications or enterprise-grade software, we leverage industry best practices and cutting-edge technologies to create efficient, secure, and user-friendly desktop applications tailored to your business requirements. From project planning to deployment and maintenance, we ensure a seamless development process and superior user experience.",
//       "feature": [
//         "Cross-Platform Development (Electron, Qt)",
//         "User Interface Design and User Experience",
//         "Backend Integration and Data Management",
//         "Security and Performance Optimization"
//       ],
//       "btntext": "Get Started",
//       "path": "/contact"
//     }
//   ];
  
  
//   return (
//     <div className={`position-relative ${className || ''}`}>
//       {/* <div className="service-shape1"></div> */}
//       <div className="container">
//         <TitleWrap className="text-center">
//           {/* <SecSubTitle>Types Of Our Services</SecSubTitle> */}
//           <SecTitle className="text-capitalize h1">What We Can Do</SecTitle>
//         </TitleWrap>
//         <Tab.Container id="serviceTab" defaultActiveKey={TABDATA[0].id}>
//           <Nav className="service-tab-menu">
//             {TABDATA.map((item) => (
//               <Nav.Link key={item.id} eventKey={item.id}>
//                 <span className="btn-img"><img src={item.icon} alt="tabicon"/></span>
//                 <span className="btn-title h6">{item.title}</span>
//                 <span className="btn-text">{item.subtitle}</span>
//               </Nav.Link>
//             ))}
//           </Nav>
//           <Tab.Content>
//             {TABDATA.map((item) => (
//               <Tab.Pane key={item.id} eventKey={item.id}>
//                 <div className="row gx-60 gy-30">
//                   <div className="col-lg-6">
//                     <img src={item.image} alt="tabthumb" style={{borderRadius: '7px', width: '100%'}} />
//                   </div>
//                   <div className="col-lg-6">
//                     <div className="about-box1">                      
//                       <h2 className="about-title h3">{item.title}</h2>
//                       <span className="about-subtitle">{item.subtitle}</span>
//                       <p className="about-text">{item.text}</p>
//                       <List className="list-style2">
//                         {item.feature.map((list, index) => (
//                           <List.Item key={index}><i className="fas fa-check-circle"/>{list}</List.Item>
//                         ))}
//                       </List>
//                       <Button path={item.path}>{item.btntext}<i className="far fa-arrow-right"/></Button>
//                     </div>
//                   </div>
//                 </div>
//               </Tab.Pane>
//             ))}
//           </Tab.Content>
//         </Tab.Container>
//       </div>
//     </div>
//   );
// }

// export default AboutSix;


import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

const ServiceSingle = ({ ...restProps }) => {
  const newArray = [
    {
      question: "Mobile App Development",
      answer: "We'll help you create customized mobile apps for iOS and Android devices, enabling you to reach your customers on-the-go. Our experts will work closely with you to understand your business goals and develop a mobile app that aligns with them."
    },
    {
      question: "Web Application Development",
      answer: "Our team will build custom web applications tailored to meet your specific needs, including e-commerce solutions, content management systems, and more."
    },
    {
      question: "Desktop Application Development",
      answer: "We'll help you create powerful desktop applications for Windows, macOS, and Linux platforms, ensuring a seamless user experience across all devices."
    },
    {
      question: "Cloud Solutions",
      answer: "Our experts will help you leverage the full potential of cloud computing, including infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS). We'll work closely with you to identify the best cloud solutions for your business needs."
    },
    {
      question: "Artificial Intelligence (AI) Solutions",
      answer: "Our team will help you integrate AI into your applications, enabling you to automate complex tasks, gain valuable insights, and make data-driven decisions. We'll work closely with you to identify the most appropriate AI solutions for your business needs."
    },
    {
      question: "DevOps Services",
      answer: "Our experts will help you streamline your development, testing, and deployment processes through our DevOps services, ensuring faster time-to-market and improved quality."
    },
    {
      question: "Testing and Quality Assurance",
      answer: "We'll help you ensure the highest quality of your applications by providing comprehensive testing and quality assurance services, including functional testing, performance testing, and security testing."
    }
  ];
  

  const statementsArray = [
    "Transform your business operations through cutting-edge mobile, web,desktop apps, cloud, and AI solutions.",
    "Enhance customer engagement through customized mobile apps and web applications.",
    "Increase efficiency and productivity through automation and streamlined development processes.",
    "Ensure the highest quality of your applications through comprehensive testing and quality assurance services.",
    "Scale your business operations efficiently through cloud computing solutions.",
    "Gain valuable insights and make data-driven decisions through AI-powered applications.",    
  ];

  return (
    <div {...restProps}>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8">
            <h2 className="h4 capitalize">
            Transform Your Operations with our Solutions            </h2>
            <p>
            WaayOut acknowledges the significance of application development and integration in the modern business world. We provide a comprehensive suite of services to support you in crafting, building, and merging state-of-the-art applications that align with your individual needs. Our team of seasoned professionals is dedicated to partnering with you to revolutionize your operations and propel business success using mobile, web, desktop apps, cloud, and AI solutions.            </p>
            <div className="row gx-0 mb-4 pb-2 pt-3">
              {/* <div className="col-xl-6">
                <img
                  src="/images/service/sr-d-1-2.jpg"
                  alt="project"
                  className="w-100"
                />
              </div> */}
              {/* <div className="col-xl-6">
                <div className="service-list-box">
                  <h3 className="h5 title">Service Features</h3>
                  <List className="list-style3">
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Risk Assessment and Management
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Compliance (GDPR, HIPAA, PCI DSS, etc.)
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Incident Response and Forensics
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Vulnerability and Penetration Testing
                    </List.Item>
                  </List>
                </div>
              </div> */}
            </div>
            <h3 className="h5">Explore our possibilities</h3>
            <Accordion
              defaultActiveKey={newArray[0].question}
              className="accordion-style1 layout2"
            >
              {newArray.map((item) => (
                <Accordion.Item key={item.question} eventKey={item.question}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h3 className="h6">By leveraging WaayOut's Cybersecurity Consulting Services, you can</h3>
            <List className="list-style2">
              {statementsArray.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
            <p className="h6">
            Don't let your business operations hold you back – let WaayOut help you
unlock your full potential with our application development and 
integration services. Contact us today to schedule a consultation and 
start realizing your business goals.
            </p>
          </div>
          <div className="col-lg-4">
            <SidebarTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
