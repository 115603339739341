import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

const ServiceSingle = ({ ...restProps }) => {
  const newArray = [
    {
      question: "Technology Vision and Roadmapping",
      answer:
        "We'll help you envision and plan for the future of your technology landscape, including identifying emerging technologies and trends that can give your business a competitive edge.",
    },
    {
      question: "TCO (Total Cost of Ownership) Analysis",
      answer:
        "We'll assess the total cost of owning and operating your current technology infrastructure, and provide recommendations for optimizing costs and improving efficiency.",
    },
    {
      question: "IT Roadmapping",
      answer:
        "We'll help you create a roadmap for your IT infrastructure, including planning for upgrades, migrations, and new deployments.",
    },
    {
      question: "Security Risk Assessment and Compliance",
      answer:
        "Our security experts will identify potential risks to your technology systems and develop a plan to mitigate those risks, ensuring compliance with relevant regulations.",
    },
    {
      question: "Data Management and Analytics",
      answer:
        "We'll help you unlock the value of your data by developing a data management strategy that incorporates data warehousing, business intelligence, and analytics tools.",
    },
    {
      question: "Cloud Computing Strategy",
      answer:
        "Our cloud experts will help you develop a comprehensive cloud strategy that aligns with your business goals, including assessing your current infrastructure, identifying areas for improvement, and developing a roadmap for cloud adoption.",
    },
    {
      question: "IT Governance and Compliance",
      answer:
        "We'll help you establish an IT governance framework that ensures your technology systems are secure, efficient, and compliant with relevant regulations.",
    },
  ];

  const statementsArray = [
    "Streamline your technology infrastructure for improved efficiency and cost savings.",
    "Enhance your business agility by developing a flexible and adaptive IT strategy.",
    "Stay ahead of the competition by leveraging emerging technologies and trends.",
    "Unlock the full potential of your data to make informed business decisions.",
  ];

  return (
    <div {...restProps}>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8">
            <h2 className="h4">
              Unlocking Your Business Potential with Technology
            </h2>
            <p>
              At WaayOut, we understand that technology is an integral part of
              any business operation. That's why we offer comprehensive
              technology strategy and planning services to help your
              organization stay ahead of the competition. Our team of
              experienced professionals will work closely with you to develop a
              tailored roadmap for leveraging technology to meet your business
              goals.
            </p>
            <div className="row gx-0 mb-4 pb-2 pt-3">
              {/* <div className="col-xl-6">
                <img
                  src="/images/service/sr-d-1-2.jpg"
                  alt="project"
                  className="w-100"
                />
              </div> */}
              {/* <div className="col-xl-6">
                <div className="service-list-box">
                  <h3 className="h5 title">Service Features</h3>
                  <List className="list-style3">
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Onboarding to the Cloud
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Migration Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Cloud Security, Monitoring & AI Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Effective Cost Solutions
                    </List.Item>
                  </List>
                </div>
              </div> */}
            </div>
            <h3 className="h5">Our Technology Strategy and Planning</h3>
            <Accordion
              defaultActiveKey={newArray[0].question}
              className="accordion-style1 layout2"
            >
              {newArray.map((item) => (
                <Accordion.Item key={item.question} eventKey={item.question}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h3 className="h6">By leveraging WaayOut's Technology Strategy and Planning services, you 
can</h3>
            <List className="list-style2">
              {statementsArray.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
            <p className="h6">
            Don't let your IT infrastructure hold you back – let WaayOut help you 
streamline your business operations today. Contact us to schedule a 
consultation and start realizing your business goals
            </p>            
          </div>
          <div className="col-lg-4">
            <SidebarTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
