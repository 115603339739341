import React from 'react';
import {
  InfoMedia,
  BannerWidget
} from '../../components';

const ProjectSingleNutrra = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="h5 pt-xl-3">From A Vision To An Application</h3>
          <p>Collaborating with Nutrra, a software company specializing in health and nutrition solutions, was a rewarding experience in our journey of digital innovation. Our mission was to assist them in the development process of their mobile application using React Native. Leveraging this technology, we aimed to provide Nutrra with a robust and scalable solution to meet their business objectives.</p>
          <h3 className="h5 pt-xl-3">The Challenge</h3>
          <p>Building a mobile application for Nutrra required addressing various challenges, including ensuring security and reliability. To achieve this, we implemented a custom solution for data management and user authentication. Additionally, we focused on optimizing performance and usability to deliver a seamless experience for Nutrra's users.</p>
          <div className="row pt-xl-1 mb-xl-2   wow fadeInUp" data-wow-delay="0.2s">
            <div className="col-sm-6 mb-30"><img src="/images/project/nutrra-image1.jpg" alt="project"/></div>
            <div className="col-sm-6 mb-30"><img src="/images/project/nutrra-image2.jpg" alt="project"/></div>
          </div>
          {/* Difficulties */}
          <h3 className="h5 pt-xl-3">Overcoming Challenges</h3>
          <p>Developing a mobile application with React Native posed certain challenges, particularly in integrating complex features and ensuring cross-platform compatibility. However, our experienced team successfully navigated these challenges by implementing efficient development practices and leveraging the flexibility of React Native.</p>
          <p>Moreover, implementing a custom solution for data management and user authentication required meticulous attention to detail and robust testing. Through rigorous testing and adherence to best practices, we ensured that Nutrra's mobile application met industry standards for security and performance.</p>
          {/* End of difficulties */}
          {/* Results */}
          <h3 className="h5 pt-xl-3">Results</h3>
          <ul>
            <li>Successful development and launch of the mobile application</li>
            <li>Enhanced security and reliability through custom data management and authentication solution</li>
            <li>Optimized performance and usability for Nutrra's users</li>
            <li>Positive feedback and increased user engagement</li>
          </ul>
          {/* End of results */}
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="Nutrra"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Category:"
              info="Mobile Application Development"
            />
          </div>
          {/* <ContactWidget
            title="Need Any Help?"
            text="Need Any Help, Call Us 24/7 For Support"
            bgImage="/images/bg/sidebox-bg-1-1.jpg"
          >
            <ContactWidget.Info><i className="fas fa-phone-alt"/><a href="tel:+6787196992">+678 719 9662‬</a></ContactWidget.Info>
            <ContactWidget.Info><i className="fas fa-envelope"/><a href="mailto:info@waayout.com">info@waayout.com</a></ContactWidget.Info>
            <ContactWidget.Info><i className="fas fa-map-marker-alt"/>Lawrenceville GA</ContactWidget.Info>
          </ContactWidget> */}
          <BannerWidget 
      title="Have Any Query?"
      image="images/widget/contact-us.png"
      btnText="Get A Quote"
      path="/contact"
    />      

        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingleNutrra;
