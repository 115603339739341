import React, { useState, useRef } from "react";
import { Button } from "../";
import { initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const FormFive = () => {
  const recaptcha = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");


  const handleCaptcha = (value) => {
    if (value) {
      setButtonEnabled(false);
    }
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (event) => {
    // Asynchronous function
    event.preventDefault();
    setLoading(true); // Set loading state to true
    // Check if name and email are provided
    const token = recaptcha.current.getValue()


    if (!token) {
      setErrorMessage("Please complete the reCAPTCHA verification.");
      setLoading(false);
      return;
    }

    if (!formData.name.trim() || !formData.email.trim()) {
      setErrorMessage("Please provide your name and email.");
      setLoading(false); // Set loading state back to false
      return;
    }
    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage("Please provide a valid email address.");
      setLoading(false); // Set loading state back to false
      return;
    }

    try {
      const functions = getFunctions(app);
      const sendEmail = httpsCallable(functions, "submitContactForm");
      const result = await sendEmail(formData);
  
      // Check the result from the Cloud Function
      if (result.data && result.data.status === "success") {
        // Handle success
        console.log("Email sent successfully");
        // Optionally, you can reset the form here
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: ""
        });
      } else {
        // Handle failure
        setErrorMessage("An error occurred while sending the email.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the contact form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form action="#" className="vs-contact-form" onSubmit={handleSubmit}>
      <div className="row gx-20">
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <i className="fal fa-user" />
        </div>
        <div className="col-md-6 form-group">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
            required
          />
          <i className="fal fa-envelope" />
        </div>
        <div className="col-12 form-group">
          <select
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required
          >
            <option value="">Select subject</option>
            <option value="Web Development">Web Development</option>
            <option value="Mobile Development">Mobile Development</option>
            <option value="Ai Solutions">Ai Solutions</option>
            <option value="Cloud Solutions">Cloud Solutions</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="col-12 form-group">
          <textarea
            name="message"
            placeholder="Type Your Message"
            value={formData.message}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-12 form-group">
          <ReCAPTCHA
            ref={recaptcha}
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleCaptcha}
          />
        </div>
        <div className="col-12">
          <Button type="submit" disabled={buttonEnabled}>
            {loading ? "Loading..." : "Submit Message"}
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FormFive;
