import React from 'react';
import { Helmet } from "react-helmet";

const SEO = ({ title, keywords, description, url, image }) => {
  return (
    <Helmet>
      <title>WaayOut - {title} - Comprehensive IT Solutions & Technology Services</title>
      {keywords && <meta name="keywords" content={keywords} />}
      {description && <meta name="description" content={description} />}
      
      {/* Open Graph meta tags for social media */}
      <meta property="og:title" content={`WaayOut - ${title} - Comprehensive IT Solutions & Technology Services`} />
      {description && <meta property="og:description" content={description} />}
      {url && <meta property="og:url" content={`https://www.waayout.com/${url}`} />}
      {image && <meta property="og:image" content={`https://www.waayout.com/${image}`} />}
      
      {/* Twitter Card meta tags for social media */}
      <meta name="twitter:title" content={`WaayOut - ${title} - Comprehensive IT Solutions & Technology Services`} />
      {description && <meta name="twitter:description" content={description} />}
      {url && <meta name="twitter:url" content={`https://www.waayout.com/${url}`} />}
      {image && <meta name="twitter:image" content={`https://www.waayout.com/${image}`} />}
      
      {/* Structured data markup */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "WaayOut",
            "url": "https://www.waayout.com/",
            "logo": "https://www.waayout.com/images/logo.png",
            "description": "${description}"
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;
