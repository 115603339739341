import React, { Fragment } from "react";
import { Seo, BreadcrumbOne, ScrollTopBtn } from "../components";
import {
  HeaderFour,
  ServiceFour,
  AboutSeven,
  TestimonialThree,
  FooterOne,
} from "../containers";
import { findSeoByPath } from "../utils/utils";

const AboutPage = () => {

  const { url, keywords, description } = findSeoByPath("about");

  return (
    <Fragment>
      <Seo
        title="About Us"
        description={description}
        keywords={keywords}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbOne
        pageName="About Us"
        bgImage="/images/breadcumb/breadcumb1.jpg"
      />
      <AboutSeven />
      <ServiceFour className="space-top space-extra-bottom" />
      <TestimonialThree className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default AboutPage;
