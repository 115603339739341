import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, FooterOne, AboutSoftware } from "../containers";
import { findSeoByPath } from "../utils/utils";

const ITStrategy = () => {
  const { url, keywords, description } = findSeoByPath("service/software");

  return (
    <Fragment>
      <Seo
        title="Application Development"
        description={description}
        keywords={keywords}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbService
        pageName="Application Development"
        bgImage="/images/breadcumb/breadcumb1.jpg"
      />

      <AboutSoftware className="space-top space-extra-bottom" />

      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITStrategy;
