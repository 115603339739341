import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderFour,
  ProjectSingleBKV,
  FooterOne
} from '../containers';

const ProjectDetails = ()=> (
  <Fragment>
    <Seo title="BKV" />
    <HeaderFour />
    <Breadcrumb pageName="BofA" subText="Portfolio" sublink="/portfolio" bgImage="/images/breadcumb/breadcumb1.jpg" />
    <ProjectSingleBKV className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default ProjectDetails;