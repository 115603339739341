import React from 'react';
import {
  ProjectBoxOne
} from '../../components';

const ProjectThree = ({...restProps})=> {

  return (
    <div {...restProps}>
      <div className="container">        
        <div className="row">
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/project-bkv.jpg"
              title="Digital Solutions"
              tag="BofA"
              path="/portfolio/digital"
            />
          </div>
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/project-grey.jpg"
              title="Custom CMS"
              tag="Turner"
              path="/portfolio/migration-cms"
            />
          </div>
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/project-tablet.jpg"
              title="Custom App Solutions"
              tag="Web Application"
              path="/portfolio/web-application"
            />
          </div>
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/project-healthcare.jpg"
              title="Mobile Development"
              tag="Tech StartUp"
              path="/portfolio/start-up-solution"
            />
          </div>
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/p-2-5.jpg"
              title="Fraud Detection System"
              tag="Machine Learning"
              path="/portfolio/fraud-detection"
            />
          </div>
          <div className="col-md-6 col-xxl-auto">
            <ProjectBoxOne
              image="images/project/project-cloud.jpg"
              title="Image Recognition System"
              tag="AI-Powered"
              path="/portfolio/img-recog-system"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectThree;