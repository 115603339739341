import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

const ServiceSingle = ({ ...restProps }) => {
  const newArray = [
    {
      question: "Cloud Strategy and Planning",
      answer: "We'll help you create a comprehensive cloud strategy that aligns with your business objectives, including assessing your current infrastructure, identifying areas for improvement, and developing a roadmap for cloud adoption."
    },
    {
      question: "Cloud Migration and Deployment",
      answer: "Our experts will help you migrate your applications and data to the cloud, ensuring a seamless transition and optimal performance. We'll also help you deploy new cloud-based systems and services as needed."
    },
    {
      question: "Cloud Security and Compliance",
      answer: "Our security professionals will identify potential risks to your cloud infrastructure and develop a plan to mitigate those risks, ensuring compliance with relevant regulations and standards."
    },
    {
      question: "Cloud Management and Governance",
      answer: "We'll help you establish an effective cloud management framework that ensures your technology systems are secure, efficient, and compliant with relevant regulations."
    },
    {
      question: "Cloud Infrastructure as a Service (IaaS)",
      answer: "Our experts will help you leverage IaaS solutions to optimize your cloud infrastructure, including assessing your current infrastructure, identifying areas for improvement, and developing a roadmap for IaaS adoption."
    },
    {
      question: "Cloud Platforms and Tools",
      answer: "We'll help you evaluate and implement the most appropriate cloud platforms and tools to meet your business needs, including AWS, Azure, Google Cloud, and more."
    },
    {
      question: "Cloud Consulting and Advisory",
      answer: "Our experts will provide ongoing consulting and advisory services to ensure your cloud infrastructure is optimized, secure, and compliant with relevant regulations."
    }
  ];

  const statementsArray = [
    "Maximize the efficiency and cost-effectiveness of your cloud infrastructure.",
    "Boost your business's agility with a cloud strategy that is both flexible and adaptive.",
    "Safeguard your organization from risks and fines by staying compliant with regulations and standards.",
    "Harness the power of your cloud infrastructure to make well-informed business decisions.",
  ];

  return (
    <div {...restProps}>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8">
            <h2 className="h4">
              The Competitive Edge You Need to Succeed
            </h2>
            <p>
            Take your business to the next level with WaayOut's cloud computing services! Stay ahead of the curve by leveraging our expertise to create a customized roadmap for success. Let us help you achieve your business goals through the power of the cloud.
            </p>
            <div className="row gx-0 mb-4 pb-2 pt-3">
              {/* <div className="col-xl-6">
                <img
                  src="/images/service/sr-d-1-2.jpg"
                  alt="project"
                  className="w-100"
                />
              </div> */}
              {/* <div className="col-xl-6">
                <div className="service-list-box">
                  <h3 className="h5 title">Service Features</h3>
                  <List className="list-style3">
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Onboarding to the Cloud
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Migration Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Cloud Security, Monitoring & AI Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Effective Cost Solutions
                    </List.Item>
                  </List>
                </div>
              </div> */}
            </div>
            <h3 className="h5">Our Cloud Computing Services include</h3>
            <Accordion
              defaultActiveKey={newArray[0].question}
              className="accordion-style1 layout2"
            >
              {newArray.map((item) => (
                <Accordion.Item key={item.question} eventKey={item.question}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h3 className="h6">By leveraging WaayOut's Cloud Computing Services, you can</h3>
            <List className="list-style2">
              {statementsArray.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
            <h3 className="h6">By leveraging WaayOut's Data Analytics and Visualization Services, you can</h3>
          <List className="list-style2">
            <List.Item>Gain actionable insights from your data to drive business growth and innovation.</List.Item>
            <List.Item>Identify trends, patterns, and anomalies to make informed strategic decisions.</List.Item>
            <List.Item>Improve operational efficiency and streamline business processes.</List.Item>
            <List.Item>Enhance customer experiences and engagement through personalized insights and recommendations.</List.Item>
            <List.Item>Stay ahead of the competition by leveraging advanced analytics and visualization techniques.</List.Item>
          </List>            
            <p className="h6">
            Don't let technology hold you back – let WaayOut help you unlock the 
power of cloud computing with our expert services. Contact us today to 
schedule a consultation and start realizing your business goals.
            </p>
          </div>
          <div className="col-lg-4">
            <SidebarTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
