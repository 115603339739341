import React from 'react';
import {
  Widget, 
  RecentPostWidget, 
} from '../../components';

// Widgets Data
import widgetsData from '../../data/widgets.json';
const {recentPosts} = widgetsData;

const SidebarOne = ({className})=> (
  <aside className={`sidebar-area ${className || ''}`}>
    <Widget widgetTitle="Recent Posts">
      {recentPosts.map(post => (
        <RecentPostWidget key={post.id}
          title={post.title}
          image={post.image}
          date={post.date}
          path={post.path}
        />
      ))}
    </Widget>
  </aside>
);

export default SidebarOne;