import React from 'react';
import {
  InfoMedia,
  ContactWidget
} from '../../components';

const ProjectSingle = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="h5 pt-xl-3">Georgia Department of Health</h3>
          <p>Partnering with the Georgia Department of Health (DPH) is a momentous achievement in our journey towards digital innovation and improving public services. Our objective was to migrate their current website to a personalized Content Management System (CMS) hosted in a private cloud. This undertaking allowed us to revamp their digital infrastructure and optimize their online presence.</p>
          <h3 className="h5 pt-xl-3">The challenge of project</h3>
          <p>In addition to revamping their web platform, we recognized the importance of extending their reach and accessibility through mobile technology. Thus, we also undertook the development of a comprehensive mobile application, tailored for both the Google Play Store and Apple App Store. This mobile app serves as a centralized hub for delivering critical health information, facilitating appointment scheduling, and providing access to vital resources, thereby empowering citizens to take control of their health and well-being.</p>
          <div className="row pt-xl-1 mb-xl-2   wow fadeInUp" data-wow-delay="0.2s">
            <div className="col-sm-6 mb-30"><img src="/images/project/dph-image2.jpg" alt="project"/></div>
            <div className="col-sm-6 mb-30"><img src="/images/project/dph-image1.jpg" alt="project"/></div>
          </div>
          {/* Difficulties */}
          <h3 className="h5 pt-xl-3">Overcoming Challenges</h3>
          <p>Migrating from an on-premises solution to the Private Cloud presented several challenges. The transition required meticulous planning and execution to ensure seamless continuity of services while mitigating potential risks. Among the difficulties encountered was the complexity of transferring large volumes of data and applications to the cloud environment without disruption to operations.</p>
          <p>Additionally, the process of extracting information from legacy systems and archives posed a significant hurdle. To address this, we leveraged advanced language processing techniques to extract, transform, and load data into the new CMS. This involved running archival assets through language processors to extract relevant information and update the CMS accordingly.</p>
          {/* End of difficulties */}
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="Georgia DPH"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Solution"
              info="Cloud & Mobile Services"
            />
          </div>
          <ContactWidget
            title="Need Any Help?"
            text="Need Any Help, Call Us 24/7 For Support"
            bgImage="/images/bg/sidebox-bg-1-1.jpg"
          >
            <ContactWidget.Info><i className="fas fa-phone-alt"/><a href="tel:+6787196992">+678 719 9662‬</a></ContactWidget.Info>
            <ContactWidget.Info><i className="fas fa-envelope"/><a href="mailto:info@waayout.com">info@waayout.com</a></ContactWidget.Info>
            <ContactWidget.Info><i className="fas fa-map-marker-alt"/>Lawrenceville GA</ContactWidget.Info>
          </ContactWidget>

        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingle;
