import React from 'react';
import {
  InfoMedia,
  BannerWidget
} from '../../components';


const ProjectSingleMolina = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="h5 pt-xl-3">Data Migration</h3>
          <p>Our collaboration with Molina, a leader in healthcare solutions, aimed to enhance their data management capabilities and combat fraud within the healthcare industry. Molina's existing relational database posed limitations in efficiently detecting and preventing fraudulent activities, prompting the need for a more sophisticated solution.</p>
          <p>We embarked on a comprehensive data migration project, transitioning Molina's vast datasets to a graph database. This decision was driven by the need for a flexible and scalable database solution capable of handling complex relationships and data structures inherent in healthcare data.</p>
          <p>The migration process involved meticulous planning and execution to ensure minimal disruption to Molina's operations. Our team collaborated closely with Molina's IT personnel to analyze data structures, identify dependencies, and develop a tailored migration strategy.</p>
          <h3 className="h5 pt-xl-3">Challenges Faced</h3>
          <p>The migration process presented several challenges, including:</p>
          <ul>
            <li>Ensuring data integrity and accuracy throughout the migration</li>
            <li>Addressing compatibility issues between Oracle and graph databases</li>
            <li>Optimizing performance and scalability of the new database solution</li>
            <li>Maintaining regulatory compliance and data security</li>
          </ul>
          <p>To overcome these challenges, we implemented robust data validation and cleansing processes, conducted extensive testing to identify and resolve compatibility issues, and implemented encryption and access controls to safeguard sensitive data.</p>
          <h3 className="h5 pt-xl-3">Results</h3>
          <p>The successful migration to the graph database provided Molina with:</p>
          <ul>
            <li>Improved fraud detection capabilities through enhanced data visualization and analysis</li>
            <li>Increased scalability to accommodate growing volumes of healthcare data</li>
            <li>Streamlined data management processes, leading to improved operational efficiency</li>
            <li>Enhanced regulatory compliance and data security measures</li>
          </ul>
          <p>Overall, our collaboration with Molina exemplifies our commitment to delivering innovative solutions that address complex challenges in the healthcare industry.</p>
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="Molina"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Category:"
              info="Data Migration and Fraud Detection"
            />
          </div>
          <BannerWidget 
            title="Have Any Query?"
            image="images/widget/contact-us.png"
            btnText="Get A Quote"
            path="/contact"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingleMolina;
