import React, {Fragment} from 'react';
import {
  Seo,
  Breadcrumb,
  ScrollTopBtn
} from '../components';
import {
  BlogSingle,
  FooterOne,
  HeaderFour
} from '../containers';

const BlogDetails = () => (
  <Fragment>
    <Seo title="Easier Life From Tech" />
    <HeaderFour />
    <Breadcrumb pageName="Easier Life From Tech"  bgImage="/images/breadcumb/breadcumb1.jpg" />
    <BlogSingle className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default BlogDetails;