import React from "react";
import { Link } from "react-router-dom";
import {
  Widget,
  AboutWidget,
  CategoryWidget,
  IconLink,
} from "../../components/";

// Widget Datas
import widgetsdata from "../../data/widgets.json";

const { pageList, exploreLinks } = widgetsdata;

const FooterOne = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="footer-wrapper footer-layout1 background-image"
      style={{ backgroundImage: "url(images/shape/bg-footer-1-1.jpg)" }}
    >
      {/* <div className="footer-top">
      <div className="container">        
        <div className="row">        
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-map-marker-alt"
              title="Office Address"
              info="259 Hilton Street, MK 256 North, United State"
            />
          </div>
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-clock"
              title="Working Hours"
              info="Weekdays 8am - 5pm"
            />
          </div>
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-phone-volume"
              title="Contact Us"
              info={<>
                <a href="mailto:info@example.com">info@example.com</a>
                <br/>
                <a href="tel:+2597462153">(+259) 746 2153</a>
              </>}
            />
          </div>
        </div>
      </div>
    </div> */}
      <div className="widget-area">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-auto">
              <Widget widgetTitle="About Us" className="footer-widget">
                <AboutWidget>
                  <AboutWidget.Text>
                    Your trusted partner for comprehensive IT solutions and
                    digital transformation.
                  </AboutWidget.Text>
                  <IconLink className="footer-social">
                    <IconLink.Item icon="fab fa-linkedin" path="/" />
                  </IconLink>
                </AboutWidget>
              </Widget>
            </div>
            <div className="col-6 col-xl-auto">
              <Widget
                widgetTitle="Links"
                className="widget_nav_menu footer-widget"
              >
                <CategoryWidget>
                  {pageList.map((cat) => (
                    <CategoryWidget.Item key={cat.name} path={cat.path}>
                      {cat.name}
                    </CategoryWidget.Item>
                  ))}
                </CategoryWidget>
              </Widget>
            </div>
            <div className="col-6 col-xl-auto">
              <Widget
                widgetTitle="Explore"
                className="widget_nav_menu footer-widget"
              >
                <CategoryWidget>
                  {exploreLinks.map((cat) => (
                    <CategoryWidget.Item key={cat.name} path={cat.path}>
                      {cat.name}
                    </CategoryWidget.Item>
                  ))}
                </CategoryWidget>
              </Widget>
            </div>
            <div className="col-md-6 col-xl-auto">
              <Widget widgetTitle="" className="footer-widget">
                <div className="recent-post">
                  <div className="media-img">
                    <Link to={`/ai-next-moves`}>
                      <img
                        src={"/images/blog/ai-article.jpg"}
                        alt="Blog"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to={`/articles/ai-revolutionizing-business-it-ops-maintence`}>
                        AI Revolutionizing Business: A Digital Transformation
                      </Link>
                    </h4>
                  </div>
                </div>

                <div className="recent-post">
                  <div className="media-img">
                    <Link to={`/ai-next-moves`}>
                      <img
                        src={"/images/blog/ai-article-2.jpg"}
                        alt="Blog"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to={`/articles/unlocking-business-potential-integrating-mobile-apps-ai`}>
                        Unlocking your business with a Mobile App and Ai
                      </Link>
                    </h4>
                  </div>
                </div>
              </Widget>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <p className="copyright-text">
            Copyright <i className="fal fa-copyright" /> {currentYear} . All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
