import React from 'react';
import Slider from 'react-slick';
import {ServiceBoxOne} from '../../components';


// Service Data
// import serviceData from '../../data/service.json';

const ServiceFour = ({...restProps})=> {

  
  const serviceData = [
    {
      "id": "6",
      "path": "/service/software",
      "image": "/images/service/sr-3-6.jpg",
      "icon": "/images/icon/process-2-1.png",
      "bgShape": "/images/bg/sr-box-bg-1.jpg",
      "bgShape2": "/images/shape/sr-bg-shape-2-1.png",
      "title": "Application Development",
      "text": "Delivering tailored software development solutions encompassing app creation, mobile app development, and system integration to meet unique business requirements."
    },
    {
      "id": "3",
      "path": "/service/cybersecurity",
      "image": "/images/service/sr-3-4.jpg",
      "icon": "/images/icon/sr-icon-3-4.png",
      "bgShape": "/images/bg/sr-box-bg-1.jpg",
      "bgShape2": "/images/shape/sr-bg-shape-2-1.png",
      "title": "Information Security",
      "text": "Offering cybersecurity assessments, risk analysis, and implementation of security solutions to protect against cyber threats, ensure compliance and peace of mind."
    },  
    {
      "id": "4",
      "path": "/service/cloud",
      "image": "/images/service/sr-3-3.jpg",
      "icon": "/images/icon/sr-icon-3-3.png",
      "bgShape": "/images/bg/sr-box-bg-1.jpg",
      "bgShape2": "/images/shape/sr-bg-shape-2-1.png",
      "title": "Cloud Computing",
      "text": "Supporting organizations in migrating their infrastructure, applications, and data to cloud platforms, as well as providing ongoing cloud management and optimization services."
    }
  ];


  const settings = {
    autoplay: false,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div {...restProps}>
      <div className="container">
        <Slider className="row" {...settings}>
          {serviceData.map(item => (
            <ServiceBoxOne key={item.id} className="layout3"
              path={item.path}
              bgImage={item.bgShape}
              icon={item.icon}
              title={item.title}
              text={item.text}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ServiceFour;