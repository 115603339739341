import React from 'react';
import {
  SecSubTitle,
  SecTitle,
  Button
} from '../../components';

const AboutSeven = ({className})=> (
  <div   className={`background-image ${className || ''}`}  style={{backgroundImage: 'url(/images/bg/about-bg-5-1.jpg)'}}>
    <div className="container container-style1">
      <div className="row flex-row-reverse align-items-center gx-70">
        <div className="col-lg-6 col-xl">
          <img src="/images/about/about-us-main.jpg" alt="aboutthumb"/>
        </div>
        <div className="col-lg-6 col-xl-auto">
          <div className="about-box2">
            <SecSubTitle><i className="fas fa-bring-forward"/>Our Motivation Behind Our Work</SecSubTitle>
            <SecTitle className="text-capitalize h1">Elevating Your Business with Inspiring Tech Solutions</SecTitle>
            <p>With a focus on innovation, expertise, and unwavering commitment, we strive to empower organizations to thrive in today's dynamic digital landscape. Discover how our comprehensive services and experienced team can help elevate your business to new heights.</p>
            <Button path="/service">What We Do<i className="far fa-long-arrow-right"/></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutSeven;