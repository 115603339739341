import React, {Fragment} from 'react';
import {
  Seo,
  Breadcrumb,
  ScrollTopBtn
} from '../components';
import {
  BlogSingleMobile,
  FooterOne,
  HeaderFour
} from '../containers';

const BlogDetails = () => (
  <Fragment>
    <Seo title="Integrating Mobile Apps with AI" />
    <HeaderFour />
    <Breadcrumb pageName="Mobile Utilizing AI" bgImage="/images/breadcumb/breadcumb1.jpg" />
    <BlogSingleMobile className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default BlogDetails;