// import React, {Fragment} from 'react';
// import {Seo, BreadcrumbService, ScrollTopBtn} from '../components';
// import {
//   HeaderFour,
//   Digital,
//   FooterOne
// } from '../containers';

// const DigitalPage = ()=> (
//   <Fragment>
//     <Seo title="Digital Transformation" />
//     <HeaderFour />
//     <BreadcrumbService pageName="Digital Transformation"  />
//     <Digital className="space-top space-extra-bottom"/>
//     <FooterOne/>
//     <ScrollTopBtn/>
//   </Fragment>
// );

// export default DigitalPage;

import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, Digital, FooterOne } from "../containers";
import { findSeoByPath } from '../utils/utils';

const ITOperations = () => {

    const { url , keywords , description }  = findSeoByPath('service/it-ops-maintence')

  return (
    <Fragment>
      <Seo
        title="IT Operations and Maintenance"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbService
        pageName="IT Operations and Maintenance"
        bgImage="/images/breadcumb/breadcumb1.jpg"
      />
      <Digital className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITOperations;
