import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

const ServiceSingle = ({ ...restProps }) => {
  const newArray = [
    {
      question: "Risk Assessment and Management",
      answer: "We'll identify potential risks to your technology systems and develop a plan to mitigate those risks, ensuring the security of your data and assets."
    },
    {
      question: "Compliance and Governance",
      answer: "Our experts will help you establish an effective cybersecurity governance framework that ensures compliance with relevant regulations and standards, protecting your organization from potential fines and legal action."
    },
    {
      question: "Incident Response and Disaster Recovery",
      answer: "We'll develop a comprehensive incident response and disaster recovery plan to ensure business continuity in the event of a cyber attack or technology failure."
    },
    {
      question: "Penetration Testing and Vulnerability Assessment",
      answer: "Our experts will conduct penetration testing and vulnerability assessments to identify potential weaknesses in your technology systems, and develop a plan to address those weaknesses."
    },
    {
      question: "Security Awareness Training",
      answer: "We'll provide ongoing security awareness training for your employees to help them recognize and avoid common cyber threats, such as phishing and social engineering attacks."
    },
    {
      question: "Third-Party Risk Management",
      answer: "Our experts will evaluate the cybersecurity risks associated with third-party vendors and service providers, and develop a plan to mitigate those risks."
    },
    {
      question: "Continuous Monitoring and Compliance",
      answer: "We'll provide ongoing monitoring and compliance services to ensure your technology systems remain secure and compliant with relevant regulations."
    }
  ];
  

  const statementsArray = [
    "Protect your business from emerging cyber threats and stay ahead of potential risks.",
    "Ensure compliance with relevant regulations and standards, avoiding potential fines and legal action.",
    "Improve your technology systems' security posture and protect your data and assets.",
    "Build a culture of security awareness among your employees, reducing the risk of cyber attacks.",
  ];

  return (
    <div {...restProps}>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8">
            <h2 className="h4">
            Protect Your Business from Cyber Threats
            </h2>
            <p>
            In the fast-paced world of business, cybersecurity is non-negotiable. That's why WaayOut offers comprehensive cybersecurity consulting services to keep your company secure and compliant. Our team of professionals will collaborate with you to craft a unique plan to shield your technology systems from cyber threats effectively.
            </p>
            <div className="row gx-0 mb-4 pb-2 pt-3">
              {/* <div className="col-xl-6">
                <img
                  src="/images/service/sr-d-1-2.jpg"
                  alt="project"
                  className="w-100"
                />
              </div> */}
              {/* <div className="col-xl-6">
                <div className="service-list-box">
                  <h3 className="h5 title">Service Features</h3>
                  <List className="list-style3">
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Risk Assessment and Management
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Compliance (GDPR, HIPAA, PCI DSS, etc.)
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Incident Response and Forensics
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Vulnerability and Penetration Testing
                    </List.Item>
                  </List>
                </div>
              </div> */}
            </div>
            <h3 className="h5">Explore our possibilities</h3>
            <Accordion
              defaultActiveKey={newArray[0].question}
              className="accordion-style1 layout2"
            >
              {newArray.map((item) => (
                <Accordion.Item key={item.question} eventKey={item.question}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h3 className="h6">By leveraging WaayOut's Cybersecurity Consulting Services, you can</h3>
            <List className="list-style2">
              {statementsArray.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
            <p className="h6">
            Don't let cybersecurity threats hold your business back – let WaayOut 
help you protect your technology systems and stay ahead of emerging 
risks.
            </p>
          </div>
          <div className="col-lg-4">
            <SidebarTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
