import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { List } from "../../components";
import { SidebarTwo } from "../";

const ServiceSingle = ({ ...restProps }) => {
  const newArray = [
    {
      question: "Monitoring and Management",
      answer: "We'll work closely with you to monitor and manage your IT infrastructure 24/7, enabling you to identify and resolve issues before they impact your operations."
    },
    {
      question: "Security Solutions",
      answer: "Our experts will help you protect your business from cyber threats through our comprehensive security solutions, including firewalls, intrusion detection systems, and antivirus software."
    },
    {
      question: "Cloud Services",
      answer: "We'll help you leverage the power of cloud computing to optimize your IT infrastructure, reduce costs, and improve scalability."
    },
    {
      question: "Data Backup and Recovery",
      answer: "Our team will work closely with you to design and implement a robust data backup and recovery solution, ensuring that your critical data is always protected and accessible."
    },
    {
      question: "Network Services",
      answer: "We'll help you design and maintain a high-performance network infrastructure, enabling you to connect your business-critical applications and devices seamlessly."
    },
    {
      question: "Server Management",
      answer: "Our experts will work closely with you to manage your server infrastructure, ensuring that it runs smoothly and efficiently, and is always up-to-date with the latest security patches and software updates."
    },
    {
      question: "Workstation Support",
      answer: "We'll provide expert support for your workstations, including hardware and software troubleshooting, and ensure that they are always running at optimal levels."
    },
    {
      question: "Disaster Recovery Solutions",
      answer: "Our team will help you develop a comprehensive disaster recovery plan to ensure business continuity in the event of an unexpected disruption."
    },
    {
      question: "IT Consulting",
      answer: "We'll work closely with you to identify areas of improvement in your IT infrastructure, and provide expert guidance on how to optimize your operations for maximum efficiency and productivity."
    }
  ];

  const statementsArray = [
    "Experience uninterrupted uptime and optimal performance, ensuring that your business runs smoothly and efficiently.",
    "Protect your business from cyber threats through our comprehensive security solutions.",
    "Reduce costs and improve scalability through the power of cloud computing.",
    "Ensure that your server infrastructure runs smoothly and efficiently, always up-to-date with the latest security patches and software updates.",
    "Get expert support for your workstations, including hardware and software troubleshooting.",
    "Develop a comprehensive disaster recovery plan to ensure business continuity in the event of an unexpected disruption.",
  ];

  return (
    <div {...restProps}>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8">
            <h2 className="h4">
            Experience Uninterrupted Uptime 
            </h2>
            <p>
            At WaayOut, we understand the importance of uninterrupted uptime and 
optimal performance in today's fast-paced digital landscape. That's why
we offer comprehensive IT operations and maintenance services to help 
you streamline your business operations and stay ahead of the 
competition. Our team of experienced professionals will work closely 
with you to ensure that your IT infrastructure is always up-to-date, 
secure, and running smoothly – so you can focus on what really matters:
growing your business.            </p>
            <div className="row gx-0 mb-4 pb-2 pt-3">
              {/* <div className="col-xl-6">
                <img
                  src="/images/service/sr-d-1-2.jpg"
                  alt="project"
                  className="w-100"
                />
              </div> */}
              {/* <div className="col-xl-6">
                <div className="service-list-box">
                  <h3 className="h5 title">Service Features</h3>
                  <List className="list-style3">
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Onboarding to the Cloud
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Migration Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Cloud Security, Monitoring & AI Services
                    </List.Item>
                    <List.Item>
                      <i className="fal fa-check-circle" />
                      Effective Cost Solutions
                    </List.Item>
                  </List>
                </div>
              </div> */}
            </div>
            <h3 className="h5">Our Technology Strategy and Planning</h3>
            <Accordion
              defaultActiveKey={newArray[0].question}
              className="accordion-style1 layout2"
            >
              {newArray.map((item) => (
                <Accordion.Item key={item.question} eventKey={item.question}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h3 className="h6">By leveraging WaayOut's Technology Strategy and Planning services, you 
can</h3>
            <List className="list-style2">
              {statementsArray.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
            <p className="h6">
            Don't let your IT infrastructure hold you back – let WaayOut help you 
streamline your business operations today. Contact us to schedule a 
consultation and start realizing your business goals.
            </p>
          </div>
          <div className="col-lg-4">
            <SidebarTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSingle;
