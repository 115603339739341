import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderFour, ProjectThree, FooterOne } from "../containers";
import { findSeoByPath } from "../utils/utils";
import { Link } from 'react-router-dom';

const Project = () => {
  // const keywords =
  //   "portfolio, projects, case studies, client work, showcase, examples, past projects, work samples, portfolio highlights, project gallery";
  // const description =
  //   "Explore WaayOut's diverse portfolio of projects, showcasing our expertise and innovation across various industries. From web development and mobile applications to cloud migration and data analytics, our portfolio highlights the successful collaborations we've had with clients to solve complex challenges and achieve business objectives. Browse through our projects to see how we've delivered cutting-edge solutions and transformed ideas into reality.";
  const { url, keywords, description } = findSeoByPath("portfolio");

  return (
    <Fragment>
      <Seo
        title="Portfolio"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <Breadcrumb
        pageName="Clients"
        bgImage="/images/breadcumb/breadcumb1.jpg"
        className="space-bottom"
      />
      <div className="container space-top">
        <div className="sec-line-wrap">
          <div className="sec-line"></div>
          <h2 className="sec-title2">Our Trusted Clients</h2>
          <div className="sec-line"></div>
        </div>
        <div className="row">
          <div className="col-md-3"><Link className="text-reset" target="_new" to="https://www.accessintel.com/"><img src="/images/project/waayout-clients-350x200-AI.png" alt="Access Intelligence"/></Link></div>
          <div className="col-md-3"><Link className="text-reset" to="/portfolio/web-application"><img src="/images/project/waayout-clients-350x200-DPH.png" alt="Georgia DPH"/></Link></div>
          <div className="col-md-3"><Link className="text-reset" to="/portfolio/migration-cms"><img src="/images/project/waayout-clients-350x200-tbs.png" alt="TBS"/></Link></div>
          <div className="col-md-3"><Link className="text-reset" to="/portfolio/start-up-solution"><img  src="/images/project/waayout-clients-350x200-nutrra.png" alt="Nutrra"/></Link></div>          
        </div>
      </div>
      <ProjectThree className="space-top space-extra-bottom" />

    
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default Project;
