import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderFour,
  ProjectSingleCentene,
  FooterOne
} from '../containers';

const ProjectDetails = ()=> (
  <Fragment>
    <Seo title="Georgia Department of Health" />
    <HeaderFour />
    <Breadcrumb pageName="Molina" subText="Portfolio" sublink="/portfolio" bgImage="/images/breadcumb/breadcumb1.jpg" />
    <ProjectSingleCentene className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default ProjectDetails;