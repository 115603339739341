import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { ImageBoxFive, FormFive } from "../../components";

const ContactTwo = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <Tab.Container id="contactTab" defaultActiveKey="tabno1">
        <Nav className="contact-tab-menu">
          {/* <Nav.Link eventKey="tabno1">
            <span className="btn-img"><img src="/images/contact/contact-1-1.jpg" alt="tabicon"/></span>
            <span className="btn-title h6">TechBiz, Germany</span>
            <span className="btn-text">86 KKL, FrankFrut</span>
          </Nav.Link> */}
          {/* <Nav.Link eventKey="tabno2">
            <span className="btn-img"><img src="/images/contact/contact-1-2.jpg" alt="tabicon"/></span>
            <span className="btn-title h6">TechBiz, Australia</span>
            <span className="btn-text">259 NYD, Canberra</span>
          </Nav.Link>
          <Nav.Link eventKey="tabno3">
            <span className="btn-img"><img src="/images/contact/contact-1-3.jpg" alt="tabicon"/></span>
            <span className="btn-title h6">TechBiz, United State</span>
            <span className="btn-text">259 NYD, NewYork</span>
          </Nav.Link> */}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="tabno1">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <div>
                  <ImageBoxFive
                    imageOne="/images/about/collab-home.jpg"
                    imageTwo="/images/about/collabration-home.jpg"
                    imageThree="/images/about/ai-home.jpg"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div
                  className="contact-box"
                  style={{ backgroundColor: "#E9F0FE" }}
                >
                  <h3 className="contact-box__title h4">Leave a Message</h3>
                  <p className="contact-box__text">We’re Ready To Help You</p>
                  <FormFive />
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </div>
);

export default ContactTwo;
