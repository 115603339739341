import React from 'react';
import {
  InfoMedia,
  BannerWidget
} from '../../components';

const ProjectSingleTBS = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          {/* Project Title and Objective */}
          <h3 className="h5 pt-xl-3">Turner Migration</h3>
          <p>The main objective of the Turner Migration project was to migrate the TNT and TBS websites from a Java-based application to Drupal, a highly versatile and scalable content management system (CMS). The migration aimed to enhance the digital infrastructure of Turner Broadcasting System (TBS) and improve the user experience for visitors to both the TNT and TBS websites.</p>
          
          {/* Challenges Faced */}
          <h3 className="h5 pt-xl-3">Challenges</h3>
          <ul>
            <li>Platform Transition: Migrating from a Java-based platform to Drupal presented several challenges, including ensuring minimal disruption to existing services, maintaining SEO ranking and traffic, and seamless integration with existing systems and databases.</li>
            <li>Data Integrity: Ensuring the integrity of data during the migration process was crucial to preserving website functionality and user experience.</li>
            <li>Training: Providing comprehensive training to TBS staff to familiarize them with the new Drupal-based CMS and ensure effective management and updating of the websites.</li>
          </ul>
          
          {/* Approach */}
          <h3 className="h5 pt-xl-3">Approach</h3>
          <ul>
            <li>Meticulous Planning: The migration process was meticulously planned to address each challenge effectively. This involved thorough analysis of existing systems, identification of potential risks, and development of a detailed migration strategy.</li>
            <li>Collaborative Execution: A collaborative approach was adopted, involving close coordination between the development team and TBS stakeholders to ensure alignment with business objectives and requirements.</li>
            <li>Quality Assurance: Extensive testing and quality assurance measures were implemented to validate the performance and functionality of the migrated websites, ensuring a seamless user experience.</li>
            <li>Comprehensive Training: Training sessions were conducted for TBS staff to familiarize them with the new Drupal-based CMS, empowering them to manage and update the websites efficiently.</li>
          </ul>
          
          <div className="row pt-xl-1 mb-xl-2 wow fadeInUp" data-wow-delay="0.2s">
            <div className="col-sm-6 mb-30"><img src="/images/project/tnt-image1.jpg" alt="project"/></div>
            <div className="col-sm-6 mb-30"><img src="/images/project/tnt-image2.jpg" alt="project"/></div>
          </div>
                    
          {/* Results */}
          <h3 className="h5 pt-xl-3">Results</h3>
          <ul>
            <li>Improved Performance: The successful migration to Drupal resulted in improved website performance, including faster page load times and enhanced responsiveness.</li>
            <li>Enhanced User Experience: Visitors to the TNT and TBS websites experienced an improved user experience, with intuitive navigation, enhanced accessibility, and improved content organization.</li>
            <li>Streamlined Content Management: The Drupal-based CMS provided TBS with greater flexibility and scalability in managing website content, enabling them to adapt to evolving business needs more efficiently.</li>
            <li>Maintained SEO Ranking: Despite the platform transition, efforts were made to ensure minimal impact on SEO ranking and traffic, preserving the websites' visibility and online presence.</li>
          </ul>
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          {/* Project Information */}
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="TBS"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Category"
              info="Application Development Cloud-Solution Services"
            />
            <InfoMedia
              icon="fas fa-globe"
              title="Website"
              info="www.tntdrama.com www.tbs.com"
            />            
          </div>
          
          {/* Contact Widget */}
          <BannerWidget 
            title="Have Any Query?"
            image="images/widget/contact-us.png"
            btnText="Get A Quote"
            path="/contact"
          />      
        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingleTBS;
