import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderFour,
  ServiceFive,
  CtaOne, 
  ProcessOne,
  TestimonialOne,
  FooterOne
} from '../containers';
import { findSeoByPath } from '../utils/utils';

const Service = ()=> {

  // const keywords = "application development, software development, information security, cybersecurity assessments, cloud computing, technology strategy, IT operations, data analytics, predictive modeling, data visualization, IT solutions, technology services";
  // const description = "WaayOut provides comprehensive IT solutions and technology services tailored to your business needs. From application and software development to information security, cybersecurity assessments, cloud computing, and technology strategy, we deliver expert guidance and innovative solutions. Our services also include IT operations, data analytics, predictive modeling, and data visualization, ensuring that your business stays ahead in the digital landscape. Trust WaayOut for reliable and effective IT solutions that propel your business forward.";
  const { url, keywords, description } = findSeoByPath("service");


  return (
  <Fragment>
    <Seo title="Services" keywords={keywords} description={description} url={url} />
    <HeaderFour />
    <Breadcrumb pageName="Services" bgImage="/images/breadcumb/breadcumb1.jpg" />
    <ServiceFive className="space-top space-extra-bottom"/>
    <CtaOne className="space"/>
    <ProcessOne className="space-top space-extra-bottom"/>
    <TestimonialOne className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
)
};

export default Service;