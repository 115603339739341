import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

// Home Pages
// import HomeOne from '../pages/HomeOne';
// import HomeTwo from '../pages/HomeTwo';
import HomeThree from '../pages/HomeThree';

// About Page
import About from '../pages/About';

// Blog Pages
// import Blog from '../pages/Blog';
// import BlogGrid from '../pages/BlogGrid';
import BlogDetails from '../pages/BlogDetails';
import BlogDetailsMobile from '../pages/BlogDetailsMobile';

// Pricing Plans Page

// Service Pages
import Service from '../pages/Service';

import ITStrategy from '../pages/ServiceITStrategy'
import Digital from '../pages/ServiceDigital'
import Cybersecurity from '../pages/ServiceCybersecurity'
import Cloud from '../pages/ServiceCloud'
import DataAnalytics from '../pages/ServiceDataAnalytics'
import Software from '../pages/ServiceSoftware'

// Team Pages
// import Team from '../pages/Team';
// import TeamDetails from '../pages/TeamDetails';

// Project Pages
import Project from '../pages/Project';

import ProjectBKV from '../pages/ProjectDetails-BKV';
import ProjectCentene from '../pages/ProjectDetails-CENTENE';
import ProjectDPH from '../pages/ProjectDetails-DPH';
import ProjectNutrra from '../pages/ProjectDetails-NUTRRA';
import ProjectTurner from '../pages/ProjectDetails-TURNER';
import ProjectWorldPay from '../pages/ProjectDetails-WORLDPAY';

// Contact Page
import Contact from '../pages/Contact';

// Not Fouond Page
import NotFound from '../pages/NotFound';


const AppRouters = () => (
  <Fragment>
    <Routes>
      <Route path="/" exact element={<HomeThree />} />
      <Route path="/about" exact element={<About/>} />
      <Route path="/service" exact element={<Service/>} />
      <Route path="/service/tech-strategy" exact element={<ITStrategy/>} />
      <Route path="/service/it-ops-maintence" exact element={<Digital/>} />
      <Route path="/service/cybersecurity" exact element={<Cybersecurity/>} />
      <Route path="/service/cloud" exact element={<Cloud/>} />
      <Route path="/service/machine-learning" exact element={<DataAnalytics/>} />
      <Route path="/service/software" exact element={<Software/>} />
      <Route path="/portfolio" exact element={<Project/>} />
      <Route path="/portfolio/digital" exact element={<ProjectBKV/>} />
      <Route path="/portfolio/fraud-detection" exact element={<ProjectCentene/>} />
      <Route path="/portfolio/web-application" exact element={<ProjectDPH/>} />
      <Route path="/portfolio/start-up-solution" exact element={<ProjectNutrra/>} />
      <Route path="/portfolio/migration-cms" exact element={<ProjectTurner/>} />
      <Route path="/portfolio/img-recog-system" exact element={<ProjectWorldPay/>} />
      <Route path="/articles/ai-revolutionizing-business-it-ops-maintence" exact element={<BlogDetails/>} />
      <Route path="/articles/unlocking-business-potential-integrating-mobile-apps-ai" exact element={<BlogDetailsMobile/>} />
      <Route path="/contact" exact element={<Contact/>} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  </Fragment>
);

export default AppRouters;

