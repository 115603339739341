import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderFour, ContactTwo, FooterOne } from "../containers";
import { findSeoByPath } from "../utils/utils";

const Contact = () => {
  const { url, keywords, description } = findSeoByPath("contact");

  return (
    <Fragment>
      <Seo
        title="Contact Us"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <Breadcrumb
        pageName="Contact Us"
        bgImage="/images/breadcumb/breadcumb1.jpg"
      />
      <ContactTwo className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default Contact;
