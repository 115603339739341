import React from 'react';
import {
  InfoMedia,
  BannerWidget,
} from '../../components';


const ProjectSingleBofA = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="h5 pt-xl-3">Custom Application Development &  Data Analytics</h3>
          <p>Partnering with Bank of America (BofA), a leading financial institution, was an exciting opportunity to innovate and enhance their operational capabilities. Our objective was to develop a custom application that enables BofA's servers to track log information effectively. Additionally, we aimed to migrate their data infrastructure to a graph database, providing advanced analytical capabilities to detect trends within their financial offerings.</p>
          <h3 className="h5 pt-xl-3">The Challenge</h3>
          <p>Creating a custom application for BofA involved overcoming various challenges, including ensuring data security, scalability, and real-time data tracking. Additionally, migrating from traditional database systems to a graph database required careful planning to ensure seamless integration and minimal disruption to existing operations.</p>
          <div className="row pt-xl-1 mb-xl-2   wow fadeInUp" data-wow-delay="0.2s">
            {/* Insert any relevant images here */}
          </div>
          {/* Difficulties */}
          <h3 className="h5 pt-xl-3">Overcoming Challenges</h3>
          <p>Our team tackled the challenges by implementing robust security measures, leveraging scalable cloud infrastructure, and adopting agile development methodologies. By collaborating closely with BofA's team, we ensured that the custom application met their specific requirements and complied with industry standards.</p>
          <p>The migration to a graph database was executed meticulously, with thorough testing and data validation procedures to maintain data integrity. Through extensive training and support, we empowered BofA's staff to utilize the new system effectively and maximize its potential for trend detection and analysis.</p>
          {/* End of difficulties */}
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="Bank of America"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Category:"
              info="Custom Application Development, Database Migration"
            />
          </div>
          <BannerWidget 
            title="Have Any Query?"
            image="images/widget/contact-us.png"
            btnText="Get A Quote"
            path="/contact"
          />      
        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingleBofA;
