import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, Digital, FooterOne } from "../containers";

import { findSeoByPath } from "../utils/utils";

const ITStrategyPage = () => {
  const { url, keywords, description } = findSeoByPath("service/tech-strategy")
  
  
  return (
    <Fragment>
      <Seo
        title="Data Analytics and Business Intelligence"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbService
        pageName="Data Analytics and Business Intelligence"
        bgImage="/images/breadcumb/breadcumb1.jpg"
      />
      <Digital className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITStrategyPage;
