import React from 'react';
import Slider from 'react-slick';
import {Button} from '../../components';


const HeroThree = ()=> {

  const HEROCONTENT = [
    {
      "id": "slider1",
      "subTitle": "Discover Our Services",
      "title": "Innovate Your Way to Success",
      "title2": "With Us!",
      "image": "images/hero/hero-3-1.jpg",
      "text": "Explore our comprehensive range of services designed to address your unique challenges and propel your business towards success.",
      // "playBtn": { "icon": "fas fa-play", "path": "https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1" },
      "btn": { "text": "Learn More", "path": "/service" }
    },
    {
      "id": "slider2",
      "subTitle": "View Our Projects",
      "title": "Achieve Excellence",
      "title2": "Together!",
      "image": "images/hero/hero-3-2.jpg",
      "text": "Discover our portfolio of successful projects and see how we've helped businesses like yours overcome challenges and achieve their goals.",
      // "playBtn": { "icon": "fas fa-play", "path": "https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1" },
      "btn": { "text": "Our Projects", "path": "/portfolio" }
    },
    {
      "id": "slider3",
      "subTitle": "Our Impactful Solutions",
      "title": "Clear Thinking Leads",
      "title2": "to Bright Futures!",
      "image": "images/hero/hero-3-3.jpg",
      "text": "Experience the power of our innovative solutions and how they've made a real difference in businesses like yours.",
      // "playBtn": { "icon": "fas fa-play", "path": "https://www.dropbox.com/s/b4k0p5swok5l8t2/videoplayback.mp4?dl=1" },
      "btn": { "text": "Let's Get Started", "path": "/contact" }
    }
  ];
  
  
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: true
  };

  return (
    <div style={{backgroundImage: 'url(images/hero/hero-bg-3-1.jpg)'}}>
      <Slider className="hero-layout3" {...settings}>
        {HEROCONTENT.map((slide, index) => (
          <div key={index}>
            <div className="hero-inner">
              <div className="container">
                <div className="row gx-60 align-items-center">
                  <div className="col-lg-6 col-xxl-auto">
                    <div className="hero-img">
                      <img src={slide.image} alt="herothumb"/>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xxl">
                    <div className="hero-content">
                      <span className="hero-subtitle">{slide.subTitle}</span>
                      <h2 className="hero-title">{slide.title} <span className="inner-text">{slide.title2}</span></h2>
                      <p className="hero-text">{slide.text}</p>
                      <div className="hero-btns">
                        <Button id={slide.id} path={slide.btn.path}>{slide.btn.text}<i className="far fa-long-arrow-right"/></Button>
                        {/* <PlayBtn path={slide.playBtn.path} className="style5"><i className={slide.playBtn.icon}/></PlayBtn> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default HeroThree;