import React from 'react';
import {
  Widget, 
  CategoryWidget,
  BannerWidget
} from '../../components';


// Widgets Data
import widgetsData from '../../data/widgets.json';
const {categoryList } = widgetsData;

const SidebarTwo = ({className})=> (
  <aside className={`service-sidebar ${className || ''}`}>
    <Widget widgetTitle="All Services" className="widget_nav_menu">
      <CategoryWidget>
        {categoryList.map(cat => (
          <CategoryWidget.Item key={cat} path={"/service"+cat.path}>{cat.name}</CategoryWidget.Item>
        ))}
      </CategoryWidget>
    </Widget>
    <BannerWidget 
      title="Have Any Query?"
      image="images/widget/contact-us.png"
      btnText="Get A Quote"
      path="/contact"
    />
  </aside>
);

export default SidebarTwo;