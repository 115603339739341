import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, ITStrategy, FooterOne } from "../containers";
import { findSeoByPath } from '../utils/utils';

const ITStrategyPage = () => {

    const { url , keywords , description }  = findSeoByPath('service/tech-strategy')


  return (
    <Fragment>
      <Seo
        title="IT Strategy and Consulting"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbService
        pageName="IT Strategy and Consulting"
        bgImage="/images/breadcumb/breadcumb1.jpg" 
      />
      <ITStrategy className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITStrategyPage;
