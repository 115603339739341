import React, { Fragment } from "react";
import { Seo, BreadcrumbService, ScrollTopBtn } from "../components";
import { HeaderFour, Cybersecurity, FooterOne } from "../containers";
import { findSeoByPath } from '../utils/utils';

const ITStrategy = () => {

    const { url , keywords , description }  = findSeoByPath('service/cybersecurity')

  return (
    <Fragment>
      <Seo
        title="Cybersecurity Solutions"
        keywords={keywords}
        description={description}
        url={url}
      />
      <HeaderFour />
      <BreadcrumbService
        pageName="Cybersecurity Solutions"
        bgImage="/images/breadcumb/breadcumb1.jpg"        
      />
      <Cybersecurity className="space-top space-extra-bottom" />
      <FooterOne />
      <ScrollTopBtn />
    </Fragment>
  );
};

export default ITStrategy;
