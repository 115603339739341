import React from 'react';
import {
  InfoMedia,
  BannerWidget
} from '../../components';


const ProjectSingleWorldpay = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h3 className="h5 pt-xl-3">Setting Up AI-Enabled Document Migration</h3>
          <p>Our collaboration with Worldpay was an exciting opportunity to drive digital transformation and streamline their operations. We assisted Worldpay in migrating from on-premises solutions to the cloud, providing them with scalable and efficient infrastructure to support their growing needs. Additionally, we implemented an innovative AI-enabled document migration system to automate the transfer of past onboarding documents to their new application.</p>
          <h3 className="h5 pt-xl-3">Challenges Faced</h3>
          <p>Migrating from on-premises solutions to the cloud posed several challenges, including data security and seamless integration with existing systems. Our team addressed these challenges by leveraging advanced cloud technologies and implementing robust security measures to safeguard Worldpay's sensitive data.</p>
          <p>Implementing an AI-enabled document migration system required careful planning and execution to ensure accuracy and efficiency. Through collaborative efforts and extensive testing, we successfully developed a solution that automated the migration process, saving Worldpay valuable time and resources.</p>
          {/* Difficulties */}
          <h3 className="h5 pt-xl-3">Overcoming Challenges</h3>
          <p>Our team navigated the challenges of migrating to the cloud and implementing AI-enabled document migration through meticulous planning and expertise in cloud architecture and artificial intelligence. By collaborating closely with Worldpay's team and understanding their unique requirements, we were able to deliver a tailored solution that met their needs and exceeded their expectations.</p>
          {/* End of difficulties */}
          {/* Results */}
          <h3 className="h5 pt-xl-3">Results</h3>
          <ul>
            <li>Successful migration from on-premises solutions to the cloud</li>
            <li>Implementation of an AI-enabled document migration system</li>
            <li>Improved efficiency and accuracy in document transfer process</li>
            <li>Enhanced data security measures to protect sensitive information</li>
            <li>Cost savings and resource optimization through automation</li>
          </ul>
          {/* End of results */}
        </div>        
        <div className="col-lg-4 mt-30 mt-lg-0">     
          <div className="project-box">
            <h3 className="project-box__title h5">About</h3>
            <InfoMedia
              icon="fas fa-user"
              title="Client"
              info="Worldpay"
            />
            <InfoMedia
              icon="fas fa-layer-group"
              title="Solutions"
              info="Cloud Migration and AI Implementation"
            />
          </div>
          <BannerWidget 
            title="Have Any Query?"
            image="images/widget/contact-us.png"
            btnText="Get A Quote"
            path="/contact"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ProjectSingleWorldpay;
