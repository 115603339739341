import React from 'react';
import {
  ImageBoxFive,
  SecSubTitle,
  SecTitle,
  InfoMedia,
  List,
  Button
} from '../../components'; 

const AboutFive = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-6 mb-30 pb-10 pb-lg-0">
          <ImageBoxFive
            imageOne="images/about/collab-home.jpg"
            imageTwo="images/about/collabration-home.jpg"
            imageThree="images/about/ai-home.jpg"
          />
        </div>
        <div className="col-lg-6 mb-30">
          <SecSubTitle><i className="fas fa-bring-forward"/>ABOUT OUR COMPANY</SecSubTitle>
          <SecTitle className="text-capitalize h1">Forging connections and constructing technology.</SecTitle>
          <InfoMedia className="exp-media"
            number="14"
            title="Years Experience"
            info="Whether it's cloud computing, artificial intelligence, or software development, we leverage our years of experience to empower businesses and propel them towards their goals."
          />
        <List className="list-style1">
          <List.Item><i className="far fa-check-circle"/>Comprehensive IT Solutions</List.Item>
          <List.Item><i className="far fa-check-circle"/>Exceptional Expert Teams</List.Item>
          <List.Item><i className="far fa-check-circle"/>Quality Business Solutions</List.Item>
          <List.Item><i className="far fa-check-circle"/>Dedicated Customer Service</List.Item>
        </List>
          <Button path="/service">Learn More<i className="far fa-arrow-right"/></Button>
        </div>
      </div>
    </div>
  </div>
);

export default AboutFive;